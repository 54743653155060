.loader{
    position: relative;
    width: 220px;
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader_back{
    position: absolute;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    border: dashed 4px #00ffe7;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 5s infinite reverse;
}
.loader_back::before{
    position: absolute;
    content: '';
    width: 96%;
    height: 96%;
    border-radius: 50%;
    border: dashed 4px #00ffe7;
    z-index: 3;
    animation: spin 4s infinite;
}
.loader_blur{
    position: absolute;
    width: 115%;
    height: 115%;
    border-radius: 50%;
    background-color: #00ffe7;
    opacity: 0.2;
    filter: blur(50px);
    z-index: 4;
}
.loader_back-item{
    position: absolute;
    width: 82%;
    height: 82%;
    border: 2px solid #00ffe7;
    border-left-color: transparent;
    border-radius: 50%;
    z-index: 3;
    opacity: 0.2;
    animation: spin 4s infinite reverse;
}
.loader_back-item:nth-child(3){
    width: 55%;
    height: 55%;
    border-right-color: transparent;
    opacity: 0.6;
    animation: spin 2s ease infinite reverse;
}
.loader_front{
    position: absolute;
    width: 46%;
    width: 46%;
    border-radius: 50%;
    background-color: #befff9;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader_front::before{
    position: absolute;
    content: '';
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background-color: #fff;
    z-index: 5;
}
.loader_front-item{
    position: absolute;
    width: 150%;
    height: 150%;
    border: 4px solid #00ffe7;
    border-radius: 50%;
    border-top-color: transparent;
    border-bottom-color: transparent;
    z-index: 5;
    animation: spin 2s infinite;
}
@keyframes spin {
    100%{
        transform: rotate(360deg);
    }
}
